import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import NavBarItem from './NavBarItem';

import MacServeLogo from './macserve.svg';
import MacServeBlackLogo from './macserve_black.svg';
import './NavBar.css';

function NavBar(props) {

  const { transparent } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [scrollAtTop, setScrollAtTop] = useState(true);

  useEffect(() => {
    const onScroll = e => {
      setScrollAtTop(e.target.documentElement.scrollTop <= 50);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [transparent, scrollAtTop]);

  const whiteNav = !transparent || !scrollAtTop || isOpen

  return (
    <Navbar className={classnames('macserve-nav', { 'shadow': whiteNav })} color={whiteNav ? 'light' : 'transparent'} dark={!whiteNav} light={whiteNav} fixed='top' expand='md'>
      <NavbarBrand className='macserve-navbrand font-weight-lighter'>
        <div className='d-flex flex-row align-items-center'>
          <img className='text-primary' src={whiteNav ? MacServeBlackLogo : MacServeLogo} width='30' height='30' alt='' />
          <div className='ml-2'>MacServe<span className='d-none d-md-inline'> | <span className='font-weight-light'>iVPN</span></span></div>
        </div>
      </NavbarBrand>

      <NavbarToggler onClick={() => setIsOpen(!isOpen)} />

      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavBarItem active href='#'><FontAwesomeIcon icon={faHome}/> Home</NavBarItem>
          <NavBarItem href={process.env.PUBLIC_URL + '/iVPN_7.5.zip'}><FontAwesomeIcon icon={faArrowDown}/> Download</NavBarItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

NavBar.defaultProps = {
  transparent: false
};

NavBar.propTypes = {
  transparent: PropTypes.bool
}

export default NavBar;
