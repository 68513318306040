import React from 'react';
import PropTypes from 'prop-types';

import { NavItem, NavLink } from 'reactstrap';

function NavBarItem(props) {
  return (
      <NavItem><NavLink active={props.active} href={props.href}>{props.children}</NavLink></NavItem>
  );
}

NavBarItem.propTypes = {
  active: PropTypes.bool,
  href: PropTypes.string.isRequired
};

export default NavBarItem;