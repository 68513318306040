import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faTrophy, faWifi, faUsers, faNetworkWired, faEye, faCogs } from '@fortawesome/free-solid-svg-icons';

import ErrorBoundary from '../Shared/ErrorBoundary';

import Features from './Features';
import Feature from './Feature';
import Goodbye from '../Goodbye';

import ivpnIcon from './iVPN.svg';
import './Home.scss';


function Home() {
  return (
    <ErrorBoundary>
      <div className='ivpn-header shadow-lg bg-gradient-primary'>
        <Container className='text-light'>
          <Row className='mb-4'>
            <Col>
              <Goodbye />
            </Col>
          </Row>
          <Row className='align-items-center mb-2'>
            <Col md>
              <img src={ivpnIcon} className='mx-auto d-block mb-3 mb-md-0 w-75' alt='iVPN Icon' />
            </Col>
            <Col md className='mr-auto text-center text-md-left'>
              <Row>
                <Col>
                  <h1 className='display-3'>i<span className='font-weight-bold'>VPN</span></h1>
                  <h2 className='font-weight-light'>Simple. Secure. Stable.</h2>
                  <p className='lead text-white-50'>The original VPN server for non-server Macs. Using Apple's built-in, rock solid VPN server. The same previously available in macOS Server.</p>
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col>
                <Button tag='a' href={process.env.PUBLIC_URL + '/iVPN_7.5.zip'} color='light' size='lg' outline className='mr-2'><FontAwesomeIcon icon={faArrowDown} /> Download</Button>
                </Col>
              </Row>

            </Col>
          </Row>
        </Container>
      </div>

      <Container className='content-container shadow-lg rounded-lg bg-light mb-0 p-3'>

        <Row>
          <Col>
            <h4 className='display-4 text-center text-primary'>Features</h4>
            <hr />
          </Col>
        </Row>

        <Features>
          <Feature header='The Original' icon={faTrophy}>
            <div>iVPN is the original VPN server for non-server Macs. Supporting everyone from individuals to large organisations since 2008.</div>
          </Feature>

          <Feature header='WiFi Hotspot?' icon={faWifi}>
            <div>Using a public WiFi hotspot? iVPN is perfect for you. Just connect to iVPN from your device and you instantly have a secure tunnel for all your internet traffic. Never worry about internet banking on the move again!</div>
          </Feature>

          <Feature header='Multiple Users' icon={faUsers}>
            <div>Set up access for as many users as you like. Usernames and passwords can be imported and exported in case you ever need to move iVPN between computers or even just as a backup. All exported data can be fully encrypted for peace of mind.</div>
          </Feature>

          <Feature header='Compatible' icon={faNetworkWired}>
            <div>Create a standards-based PPTP and L2TP VPN server on your home or office Mac. With just a few details and a couple of clicks, you'll be ready to connect securely to your Mac from anywhere in the world and from nearly any device!</div>
          </Feature>

          <Feature header='Monitor' icon={faEye}>
            <div>You can easily keep an eye on all connections to your Mac with the built-in iVPN Monitor. You can also check what IP address they have connected from and how long they've been connected.</div>
          </Feature>

          <Feature header='Advanced Features...' icon={faCogs}>
            <div>...but only if you need them! Support for a directory server, custom DNS and static routes means that iVPN can fit into nearly any network environment.</div>
          </Feature>
        </Features>
      </Container>

    </ErrorBoundary>
  );
}

export default Home;