import React from 'react';
import PropTypes from 'prop-types';

import { Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Feature(props) {

  const { icon, header, children } = props;

  return (
    <Media className='feature m-3'>
      <div className='rounded rounded-lg bg-primary text-light p-2 mr-3'>
        <FontAwesomeIcon icon={icon} size='2x'/>
      </div>
      <Media body>
        <h5 className='text-primary mt-0'>
          {header}
        </h5>
        <div className='text-dark'>
          {children}
        </div>
      </Media>
    </Media>
  );
}

Feature.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
}

export default Feature;