import React from 'react';

import NavBar from './NavBar';
import ErrorBoundary from './Shared/ErrorBoundary';

import Home from './Home/';

import './App.css';

function App() {
  return (
    <ErrorBoundary>
        <div className="App">
          <header>
            <NavBar transparent={true} />
          </header>
          
          <Home />

        </div>
    </ErrorBoundary>
  );
}

export default App;
