import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap'; 

class InternalServerError extends Component {
  render() {
    return (
      <Container>
          <Row>
            <Col>
              <h1 className='display-3'>Oops!</h1>
              <h3>Something went wrong. I'm sure we're already working hard to fix it, but just in case, <a href='mailto:support@macserve.org.uk'>drop us an email</a>. Thanks!</h3>
            </Col>
          </Row>
        </Container>
    );
  }
}

export default InternalServerError;