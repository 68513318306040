import React, { useState } from 'react';
import { Alert, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

function Goodbye() {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);

  return (
    <div>
      <Alert color='danger' className='text-center'>
        This project is no longer maintained. <a href='#' onClick={toggle} className='alert-link'>More info...</a>
      </Alert>
      <Modal isOpen={modal} toggle={toggle} centered size='lg' backdrop='static'>
        <ModalBody className='text-center'>
          <Alert color='danger' className='text-center'>
            This project is no longer maintained.
          </Alert>
          <h3 className='font-weight-lighter'><span role='img' aria-label='hand waving'>👋</span> All good things...</h3>
          <p>
            It is with a heavy heart that I must say goodbye. After a good run of 12 years, it has become clear that the future for iVPN is bleak.
            Since the release of macOS Catalina, iVPN has no longer functioned correctly due to a bug introduced by Apple. Sadly, it seems Apple has no interest
            in fixing this bug since their VPN server is no longer recieving maintenance.
          </p>

          <h3 className='font-weight-lighter'><span role='img' aria-label='thank you'>🙏</span> Thank You!</h3>
          <p>
            So all that's left to say is a <strong>massive</strong> thank you to everyone who has supported MacServe and iVPN over the years. Particularly to the early adopters
            and donors back at the start, in 2008. You gave me the confidence and guidance to help me build something people loved, and for that I will be forever grateful. The support
            email (<a href="mailto:support@macserve.org.uk">support@macserve.org.uk</a>) will still be live for a few more months in case you have any questions.
          </p>

          <p>
            <img height='128' width='128' alt='thank you gif' src='https://media.giphy.com/media/3o6ozuHcxTtVWJJn32/giphy.gif' />
          </p>
          <p>
            Best Wishes,<br />
            Alex<br />
            <FontAwesomeIcon icon={faHeart} className='text-danger' /> 
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Continue</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Goodbye;