import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

function Features(props) {

  const { children } = props;

  return (
    <Row className='ml-md-5 mr-md-5'>
      {children.map((child, idx) => 
        <Col key={idx} xs={12} md={6}>
          {child}
        </Col>
      )}
    </Row>
  )
}

Features.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
}

export default Features;